import ApiService from "../libs/api-service";
import UserManagement from "../libs/client-user-management";
import AppConfig from "../config/AppConfig";
import qs from "qs";
import axios from "axios";

ApiService.Config = {
  baseURL: AppConfig.API_URL,
  tokenHandler: UserManagement,
  refreshTokenResource: {
    baseURL: `${AppConfig.API_URL}/ums/v1`,
    uri: "users/current/refreshToken",
    method: "POST",
    requireAuth: false,
  },
  timeout: 60000 * 5, // 5 minutes
};

const paramsSerializer = (params) => {
  return qs.stringify(params, { allowDots: true });
};

const arraySerializer = (params) => {
  return qs.stringify(params, { arrayFormat: "repeat" });
};

const Resources = {
  // fetchUserById:              {
  // 	uri:              'ums/v1/users',
  // 	method:           'GET',
  // 	requireAuth:      true,
  // },
  fetchUsers: {
    uri: "search/v1/user",
    method: "GET",
    requireAuth: true,
    paramsSerializer,
  },
  addUser: {
    uri: "facade/v1/user",
    method: "POST",
    requireAuth: true,
  },
  fetchUserGroups: {
    uri: "organization/v1/userGroups/subordinates",
    method: "GET",
    requireAuth: true,
  },
  updateUser: {
    uri: "facade/v1/user",
    method: "PUT",
    requireAuth: true,
  },
  fetchDeviceTypes: {
    uri: "device/v1/lumDeviceTypes",
    method: "GET",
    requireAuth: true,
  },
  disableDevice: {
    uri: "device/v1/lumDevice/disable",
    method: "PUT",
    requireAuth: true,
  },
  deactivateDevice: {
    uri: "device/v1/lumDevice/types",
    method: "PUT",
    requireAuth: true,
  },
  reactivateDevice: {
    uri: "device/v1/lumDevice/types",
    method: "PUT",
    requireAuth: true,
  },
  getDevicesWithGroups: {
    uri: "search/v1/update/device",
    method: "GET",
    requireAuth: true,
    paramsSerializer,
  },
  getDevices: {
    uri: "search/v1/device",
    method: "GET",
    requireAuth: true,
    paramsSerializer,
  },
  addDevice: {
    uri: "facade/v1/device",
    method: "POST",
    requireAuth: true,
  },
  getCountries: {
    uri: "organization/v1/country/all",
    method: "GET",
    requireAuth: true,
  },
  fetchManagers: {
    uri: "organization/v1/user/potentialManagers",
    method: "GET",
    requireAuth: true,
  },
  fetchUser: {
    uri: "facade/v1/user",
    method: "GET",
    requireAuth: true,
  },
  fetchAssignedUsers: {
    uri: "organization/v1/device/types",
    method: "GET",
    requireAuth: true,
  },
  updateAssignedUsers: {
    uri: "organization/v1/device/types",
    method: "POST",
    requireAuth: true,
  },
  fetchCustomerDetails: {
    uri: "organization/v1/customers/types",
    method: "GET",
    requireAuth: true,
  },
  updateDeviceProperties: {
    uri: "facade/v1/device/properties/deviceTypes",
    method: "PATCH",
    requireAuth: true,
  },
  getDeviceProperties: {
    uri: "facade/v1/device/properties/deviceTypes",
    method: "GET",
    requireAuth: true,
  },
  getLocationsHierarchy: {
    uri: "organization/v1/country/hierarchy",
    method: "GET",
    requireAuth: true,
  },
  getDeviceTypesHierarchy: {
    uri: "device/v1/lumDeviceTypes/hierarchy",
    method: "GET",
    requireAuth: true,
  },
  fetchGroups: {
    uri: "search/v1/update/groups",
    method: "GET",
    requireAuth: true,
    paramsSerializer,
  },
  fetchGroupsWithStats: {
    uri: "search/v1/update/groups/statistics",
    method: "GET",
    requireAuth: true,
    paramsSerializer,
  },
  fetchGroup: {
    uri: "",
    method: "GET",
    requireAuth: true,
  },
  createGroup: {
    uri: "update/v1/group/composed",
    method: "POST",
    requireAuth: true,
  },
  getUploadUrl: {
    uri: "update/v1/version/upload-url",
    method: "GET",
    requireAuth: true,
  },
  createUpdateConfigurationForV2c: {
    uri: "mock",
    method: "POST",
    requireAuth: true,
  },
  fetchPotentialGroupVersions: {
    uri: "update/v1/group/composed",
    method: "GET",
    requireAuth: true,
  },
  fetchGroupDevices: {
    uri: "search/v1/update/groups",
    method: "GET",
    requireAuth: true,
    paramsSerializer,
  },
  updateAssignedDevices: {
    uri: "facade/v1/update/composed",
    method: "PUT",
    requireAuth: true,
  },
  updateAssignedDevicesWithAllMode: {
    uri: "facade/v1/update/composed",
    method: "PUT",
    requireAuth: true,
  },
  createLumenisXVersion: {
    uri: "update/v1/version/lumx",
    method: "POST",
    requireAuth: true,
  },
  createSwVersion: {
    uri: "update/v1/version/software",
    method: "POST",
    requireAuth: true,
  },
  fetchLumenisXVersions: {
    uri: "update/v1/version/lumx",
    method: "GET",
    requireAuth: true,
    paramsSerializer,
  },
  fetchSwVersions: {
    uri: "update/v1/version/software",
    method: "GET",
    requireAuth: true,
    paramsSerializer,
  },
  fetchSecurityVersions: {
    uri: "update/v1/securityVersion/search",
    method: "POST",
    requireAuth: true,
    paramsSerializer,
  },
  patchLumxVersion: {
    uri: "update/v1/version",
    method: "PATCH",
    requireAuth: true,
  },
  patchSwVersion: {
    uri: "update/v1/version",
    method: "PATCH",
    requireAuth: true,
  },
  uploadSecurityVersion: {
    uri: "update/v1/securityVersion/presignedUrl?version={version}",
    method: "GET",
    requireAuth: true,
  },
  getSecurityVersionMetadataPreview: {
    uri: "update/v1/securityVersion/metadata?version={version}",
    method: "GET",
    requireAuth: true,
  },
  patchSecurityVersion: {
    uri: "update/v1/securityVersion",
    method: "PATCH",
    requireAuth: true,
  },
  createSecurityVersion: {
    uri: "update/v1/securityVersion",
    method: "POST",
    requireAuth: true,
  },
  deleteSecurityVersion: {
    uri: "update/v1/securityVersion",
    method: "DELETE",
    requireAuth: true,
  },
  patchGroup: {
    uri: "facade/v1/update/composed",
    method: "PATCH",
    requireAuth: true,
  },
  getConfigurationUploadUrl: {
    uri: "update/v1/configuration/upload-url",
    method: "GET",
    requireAuth: true,
  },
  createConfiguration: {
    uri: "update/v2/configuration",
    method: "POST",
    requireAuth: true,
  },
  searchConfigurations: {
    uri: "update/v2/configuration/search",
    method: "POST",
    requireAuth: true,
  },
  fetchUserSettings: {
    uri: "organization/v1/settings",
    method: "GET",
    requireAuth: true,
  },
  updateUserSettings: {
    uri: "organization/v1/settings",
    method: "PATCH",
    requireAuth: true,
  },
  fetchAlarms: {
    uri: "search/v1/alarm",
    method: "GET",
    requireAuth: true,
    paramsSerializer,
  },
  fetchDeviceAlarms: {
    uri: "alarm/v1/alarms/device/types",
    method: "GET",
    requireAuth: true,
    paramsSerializer,
  },
  fetchAlarmStates: {
    uri: "alarm/v1/states",
    method: "GET",
    requireAuth: true,
  },
  fetchWaitingForApproveConsents: {
    uri: "consent/v1/users/self/consents",
    method: "GET",
    requireAuth: true,
  },
  getConsent: {
    uri: "consent/v1/consents/{consentId}/locales/{localeName}",
    method: "GET",
    requireAuth: true,
  },
  getAllUserConfirmedConsents: {
    uri: "consent/v1/users/self/consents/confirmed",
    method: "GET",
    requireAuth: true,
  },
  getUserConfirmedConsent: {
    uri: "consent/v1/users/self/consents/{consentId}/confirmed",
    method: "GET",
    requireAuth: true,
  },
  confirmConsent: {
    uri: "consent/v1/users/self/consents/confirm",
    method: "POST",
    requireAuth: true,
  },
  changeLogMode: {
    uri: "device/v1/lumDevice/types",
    method: "PUT",
    requireAuth: true,
  },
  downloadFiles: {
    uri: "file_ingestion/v1/files/devices",
    method: "GET",
    requireAuth: true,
  },
  fetchFiles: {
    uri: "file_ingestion/v1/files",
    method: "GET",
    requireAuth: true,
    paramsSerializer,
  },
  downloadFilesByIds: {
    uri: "file_ingestion/v1/files/download",
    method: "GET",
    requireAuth: true,
    paramsSerializer: arraySerializer,
  },
  deleteFilesByIds: {
    uri: "file_ingestion/v1/files",
    method: "DELETE",
    requireAuth: true,
    paramsSerializer,
  },
  unregisterDevice: {
    uri: "device/v1/lumDevice/types/{deviceType}/serialNumbers/{deviceSerialNumber}/unregister",
    method: "DELETE",
    requireAuth: true,
  },
  fetchDeviceCommands: {
    uri: "device/v1/commands/types/{deviceType}/serialNumbers/{deviceSerialNumber}/commands/latest",
    method: "GET",
    requireAuth: true,
  },
  startCommand: {
    uri: "device/v1/commands/types/{deviceType}/serialNumbers/{deviceSerialNumber}/commands/{commandType}",
    method: "POST",
    requireAuth: true,
  },
  updateCommand: {
    uri: "device/v1/commands/types/{deviceType}/serialNumbers/{deviceSerialNumber}/commands/{commandType}",
    method: "PATCH",
    requireAuth: true,
  },
  searchCommands: {
    uri: "device/v1/commands",
    method: "GET",
    requireAuth: true,
    paramsSerializer,
  },
  searchLicenses: {
    uri: "device/v1/licenses/search",
    method: "POST",
    requiredAuth: true
  },
  regenerateLicense: {
    uri: "device/v1/licenses/{licenseId}/regenerate",
    method: "POST",
    requiredAuth: true
  },
  validateLicense: {
    uri: "device/v1/licenses/{licenseId}/validate",
    method: "POST",
    requiredAuth: true
  },
  updateLicense: {
    uri: "device/v1/licenses/{licenseId}",
    method: "PATCH",
    requiredAuth: true
  },
  viewLicense: {
    uri: "device/v1/licenses/{licenseId}/decrypt",
    method: "GET",
    requiredAuth: true
  },
  abortLicenseActions: {
    uri: "device/v1/licenses/{licenseId}/abort",
    method: "PATCH",
    requiredAuth: true
  },
  generateLogs: {
    uri: "device/v1/logs/types/{deviceType}/serialNumbers/{deviceSerialNumber}/generate-logs",
    method: "POST",
    requiredAuth: true
  },
  downloadLicense: {
    uri: "device/v1/licenses/{licenseId}/download",
    method: "GET",
    requiredAuth: true
  },
};

// use can use https://www.mocky.io/ to simulate respose and delay the response
const callMockAPI = (mockUrl) => {
  const resource = {
    uri: mockUrl,
    method: "POST",
  };

  return ApiService.sendRequest({ resource });
};

/**
|--------------------------------------------------
| The server service - expose interface to the API.
| Every method returns a promise.
|--------------------------------------------------
 */
const BackendService = {
  // fetchUserById:              (userId) => ApiService.sendRequest({ resource: Resources.fetchUserById, additionalPath: userId }),
  fetchUsers: (params) =>
    ApiService.sendRequest({
      resource: Resources.fetchUsers,
      params: { includeGroups: true, ...params },
    }),
  addUser: (data) =>
    ApiService.sendRequest({ resource: Resources.addUser, data }),
  fetchUserGroups: () =>
    ApiService.sendRequest({ resource: Resources.fetchUserGroups }),
  updateUser: ({ userId, data }) =>
    ApiService.sendRequest({
      resource: Resources.updateUser,
      additionalPath: userId,
      data,
    }),
  fetchDeviceTypes: () =>
    ApiService.sendRequest({ resource: Resources.fetchDeviceTypes }),
  // fetchDeviceTypes:  (params) => callMockAPI('http://www.mocky.io/v2/5e8c5bf32f0000acdd88ca23'),
  fetchDevicesWithGroups: (params) =>
    ApiService.sendRequest({
      resource: Resources.getDevicesWithGroups,
      params,
    }),
  fetchDevices: (params) =>
    ApiService.sendRequest({ resource: Resources.getDevices, params }),
  addDevice: (data) =>
    ApiService.sendRequest({ resource: Resources.addDevice, data }),
  fetchCountries: () =>
    ApiService.sendRequest({ resource: Resources.getCountries }),
  fetchPotentialManagers: (userGroupId) =>
    ApiService.sendRequest({
      resource: Resources.fetchManagers,
      params: { userGroupId },
    }),
  fetchUser: (userId) =>
    ApiService.sendRequest({
      resource: Resources.fetchUser,
      additionalPath: userId,
    }),
  fetchAssignedUsers: ({ deviceSerialNumber, deviceType }) =>
    ApiService.sendRequest({
      resource: Resources.fetchAssignedUsers,
      additionalPath: `${deviceType}/serialNumbers/${deviceSerialNumber}`,
    }),
  updateAssignedUsers: ({ deviceSerialNumber, deviceType, data }) =>
    ApiService.sendRequest({
      resource: Resources.updateAssignedUsers,
      additionalPath: `${deviceType}/serialNumbers/${deviceSerialNumber}`,
      data,
    }),
  fetchCustomerDetails: ({ deviceSerialNumber, deviceType }) =>
    ApiService.sendRequest({
      resource: Resources.fetchCustomerDetails,
      additionalPath: `${deviceType}/serialNumbers/${deviceSerialNumber}`,
    }),
  updateDeviceProperties: ({ deviceSerialNumber, deviceType, data }) =>
    ApiService.sendRequest({
      resource: Resources.updateDeviceProperties,
      additionalPath: `${deviceType}/deviceSerialNumbers/${deviceSerialNumber}`,
      data,
    }),
  fetchDeviceProperties: ({ deviceSerialNumber, deviceType }) =>
    ApiService.sendRequest({
      resource: Resources.getDeviceProperties,
      additionalPath: `${deviceType}/deviceSerialNumbers/${deviceSerialNumber}`,
    }),
  disableDevice: (data) =>
    ApiService.sendRequest({ resource: Resources.disableDevice, data }),
  deactivateDevice: ({ deviceSerialNumber, deviceType }) =>
    ApiService.sendRequest({
      resource: Resources.deactivateDevice,
      additionalPath: `${deviceType}/serialNumbers/${deviceSerialNumber}/deactivate`,
    }),
  reactivateDevice: ({ deviceSerialNumber, deviceType }) =>
    ApiService.sendRequest({
      resource: Resources.reactivateDevice,
      additionalPath: `${deviceType}/serialNumbers/${deviceSerialNumber}/reactivate`,
    }),
  fetchLocationsHierarchy: () =>
    ApiService.sendRequest({ resource: Resources.getLocationsHierarchy }),
  fetchDeviceTypesHierarchy: () =>
    ApiService.sendRequest({ resource: Resources.getDeviceTypesHierarchy }),
  // fetchDeviceTypesHierarchy:  (params) => callMockAPI('http://www.mocky.io/v2/5e8c58502f0000590088c9f0'),
  fetchAlarms: (params) =>
    ApiService.sendRequest({ resource: Resources.fetchAlarms, params }),
  fetchDeviceAlarms: ({ deviceSerialNumber, deviceType, params }) =>
    ApiService.sendRequest({
      resource: Resources.fetchDeviceAlarms,
      additionalPath: `${deviceType}/serialNumbers/${deviceSerialNumber}`,
      params,
    }),
  fetchGroups: (params) =>
    ApiService.sendRequest({ resource: Resources.fetchGroups, params }),
  fetchGroupsWithStats: (params) =>
    ApiService.sendRequest({ resource: Resources.fetchGroupsWithStats, params }),
  createGroup: (data) =>
    ApiService.sendRequest({ resource: Resources.createGroup, data }),
  fetchSwVersions: (params) =>
    ApiService.sendRequest({ resource: Resources.fetchSwVersions, params }),
  fetchSecurityVersions: (data) =>
    ApiService.sendRequest({ resource: Resources.fetchSecurityVersions, data }),
  getUploadUrl: () =>
    ApiService.sendRequest({ resource: Resources.getUploadUrl }),
  uploadFileToS3: (uploadUrl) => { }, //TODO: add code that will upload to s3 (probably using axios)
  createUpdateConfigurationForV2c: (params) =>
    ApiService.sendRequest({
      resource: Resources.createUpdateConfigurationForV2c,
      params,
    }),
  fetchLumenisXVersions: (params) =>
    ApiService.sendRequest({
      resource: Resources.fetchLumenisXVersions,
      params,
    }),
  createLumenisXVersion: ({ data, versionId }) =>
    ApiService.sendRequest({
      resource: Resources.createLumenisXVersion,
      data,
      additionalPath: versionId,
    }),
  createSwVersion: ({ data, versionId }) =>
    ApiService.sendRequest({
      resource: Resources.createSwVersion,
      data,
      additionalPath: versionId,
    }),
  updateAssignedDevices: ({ data, groupId }) =>
    ApiService.sendRequest({
      resource: Resources.updateAssignedDevices,
      data,
      additionalPath: `${groupId}/devices`,
    }),
  updateAssignedDevicesWithAllMode: ({ groupId, data }) =>
    ApiService.sendRequest({
      resource: Resources.updateAssignedDevicesWithAllMode,
      data,
      additionalPath: `${groupId}/devices/all`,
    }),
  fetchPotentialGroupVersions: (groupId) =>
    ApiService.sendRequest({
      resource: Resources.fetchPotentialGroupVersions,
      additionalPath: `${groupId}/versions`,
    }),
  patchGroup: ({ data, groupId }) =>
    ApiService.sendRequest({
      resource: Resources.patchGroup,
      data,
      additionalPath: `${groupId}`,
    }),
  fetchGroupDevices: ({ params, groupId }) =>
    ApiService.sendRequest({
      resource: Resources.fetchGroupDevices,
      params,
      additionalPath: `${groupId}/devices`,
    }),
  mockBackendCall: () =>
    new Promise((resolve, reject) => {
      setTimeout(() => resolve({}), 2000);
    }),
  uploadFile: (url, file) => axios({ method: "put", url, data: file }),
  patchLumxVersion: ({ data, versionId }) =>
    ApiService.sendRequest({
      resource: Resources.patchLumxVersion,
      data,
      additionalPath: versionId,
    }),
  patchSwVersion: ({ data, versionId }) =>
    ApiService.sendRequest({
      resource: Resources.patchSwVersion,
      data,
      additionalPath: versionId,
    }),
  patchSecurityVersion: ({ data, versionId }) =>
    ApiService.sendRequest({
      resource: Resources.patchSecurityVersion,
      data,
      additionalPath: versionId,
    }),
  createSecurityVersion: ({ versionId, data }) =>
    ApiService.sendRequest({
      resource: Resources.createSecurityVersion,
      data,
      additionalPath: versionId,
    }),
  deleteSecurityVersion: ({ versionId }) =>
    ApiService.sendRequest({
      resource: Resources.deleteSecurityVersion,
      additionalPath: versionId,
    }),
  uploadSecurityVersion: ({ version }) =>
    ApiService.sendRequest({
      resource: {
        ...Resources.uploadSecurityVersion,
        uri: Resources.uploadSecurityVersion.uri.replace(
          "{version}",
          version
        ),
      }
    }),
  getSecurityVersionMetadataPreview: ({ versionId }) =>
    ApiService.sendRequest({
      resource: {
        ...Resources.getSecurityVersionMetadataPreview,
        uri: Resources.getSecurityVersionMetadataPreview.uri.replace(
          "{version}",
          versionId
        ),
      }
    }),
  getConfigurationUploadUrl: () =>
    ApiService.sendRequest({ resource: Resources.getConfigurationUploadUrl }),
  createConfiguration: ({ data, configurationId }) =>
    ApiService.sendRequest({
      resource: Resources.createConfiguration,
      data,
      additionalPath: configurationId,
    }),
  searchConfigurations: ({ data }) =>
    ApiService.sendRequest({ resource: Resources.searchConfigurations, data }),
  fetchUserSettings: () =>
    ApiService.sendRequest({ resource: Resources.fetchUserSettings }),
  updateUserSettings: ({ data }) =>
    ApiService.sendRequest({ resource: Resources.updateUserSettings, data }),
  fetchAlarmStates: () =>
    ApiService.sendRequest({ resource: Resources.fetchAlarmStates }),
  fetchWaitingForApproveConsents: () =>
    ApiService.sendRequest({
      resource: Resources.fetchWaitingForApproveConsents,
    }),
  getConsent: ({ consentId, localeName }) =>
    ApiService.sendRequest({
      resource: {
        ...Resources.getConsent,
        uri: Resources.getConsent.uri
          .replace("{consentId}", consentId)
          .replace("{localeName}", localeName),
      },
    }),
  getAllUserConfirmedConsents: () =>
    ApiService.sendRequest({ resource: Resources.getAllUserConfirmedConsents }),
  getUserConfirmedConsent: ({ consentId }) =>
    ApiService.sendRequest({
      resource: {
        ...Resources.getUserConfirmedConsent,
        uri: Resources.getUserConfirmedConsent.uri.replace(
          "{consentId}",
          consentId
        ),
      },
    }),
  confirmConsent: ({ data }) =>
    ApiService.sendRequest({ resource: Resources.confirmConsent, data }),
  changeLogMode: ({ deviceSerialNumber, deviceType, data }) =>
    ApiService.sendRequest({
      resource: Resources.changeLogMode,
      additionalPath: `${deviceType}/serialNumbers/${deviceSerialNumber}/update-logs-mode`,
      data,
    }),
  downloadFiles: (deviceId) =>
    ApiService.sendRequest({
      resource: Resources.downloadFiles,
      additionalPath: deviceId,
    }),
  unregisterDevice: ({ deviceSerialNumber, deviceType }) =>
    ApiService.sendRequest({
      resource: {
        ...Resources.unregisterDevice,
        uri: Resources.unregisterDevice.uri
          .replace("{deviceSerialNumber}", deviceSerialNumber)
          .replace("{deviceType}", deviceType),
      },
    }),
  fetchDeviceCommands: ({ deviceSerialNumber, deviceType }) =>
    ApiService.sendRequest({
      resource: {
        ...Resources.fetchDeviceCommands,
        uri: Resources.fetchDeviceCommands.uri
          .replace("{deviceType}", deviceType)
          .replace("{deviceSerialNumber}", deviceSerialNumber),
      },
    }),
  startCommand: ({
    deviceType,
    deviceSerialNumber,
    commandType,
    params,
    customMessage,
  }) => {
    const data = {
      parameters: params,
      customMessage: customMessage,
    };
    return ApiService.sendRequest({
      resource: {
        ...Resources.startCommand,
        uri: Resources.startCommand.uri
          .replace("{deviceType}", deviceType)
          .replace("{deviceSerialNumber}", deviceSerialNumber)
          .replace("{commandType}", commandType),
      },
      data,
    });
  },
  updateCommand: ({
    deviceType,
    deviceSerialNumber,
    commandType,
    params,
    status,
  }) => {
    const data = {
      parameters: params ? params : {},
      status,
    };
    return ApiService.sendRequest({
      resource: {
        ...Resources.updateCommand,
        uri: Resources.updateCommand.uri
          .replace("{deviceType}", deviceType)
          .replace("{deviceSerialNumber}", deviceSerialNumber)
          .replace("{commandType}", commandType),
      },
      data,
    });
  },
  searchCommands: (params) => {
    return ApiService.sendRequest({
      resource: Resources.searchCommands,
      params,
    });
  },
  fetchFiles: (params) =>
    ApiService.sendRequest({ resource: Resources.fetchFiles, params }),
  downloadFilesByIds: (params) =>
    ApiService.sendRequest({ resource: Resources.downloadFilesByIds, params }),
  deleteFilesByIds: (params) =>
    ApiService.sendRequest({ resource: Resources.deleteFilesByIds, params }),
  searchLicenses: (data) => (ApiService.sendRequest({ resource: Resources.searchLicenses, data })),
  regenerateLicense: ({ id }) => {
    return ApiService.sendRequest({
      resource: {
        ...Resources.regenerateLicense,
        uri: Resources.regenerateLicense.uri
          .replace("{licenseId}", id)
      }
    });
  },
  validateLicense: ({ id }) => {
    return ApiService.sendRequest({
      resource: {
        ...Resources.validateLicense,
        uri: Resources.validateLicense.uri
          .replace("{licenseId}", id)
      }
    });
  },
  disableLicense: ({ id }) => {
    return ApiService.sendRequest({
      resource: {
        ...Resources.updateLicense,
        uri: Resources.updateLicense.uri
          .replace("{licenseId}", id)
      },
      data: {
        command: "DISABLED"
      }
    });
  },
  enableLicense: ({ id }) => {
    return ApiService.sendRequest({
      resource: {
        ...Resources.updateLicense,
        uri: Resources.updateLicense.uri
          .replace("{licenseId}", id)
      },
      data: {
        command: "ACTIVE"
      }
    });
  },
  viewLicense: ({ id }) => {
    return ApiService.sendRequest({
      resource: {
        ...Resources.viewLicense,
        uri: Resources.viewLicense.uri
          .replace("{licenseId}", id)
      }
    });
  },
  abortLicenseActions: ({ id }) => {
    return ApiService.sendRequest({
      resource: {
        ...Resources.abortLicenseActions,
        uri: Resources.abortLicenseActions.uri
          .replace("{licenseId}", id)
      }
    });
  },
  generateLogs: ({
    deviceType,
    deviceSerialNumber,
    params,
    customMessage,
  }) => {
    const data = {
      parameters: params,
      customMessage: customMessage,
    };
    return ApiService.sendRequest({
      resource: {
        ...Resources.generateLogs,
        uri: Resources.generateLogs.uri
          .replace("{deviceType}", deviceType)
          .replace("{deviceSerialNumber}", deviceSerialNumber)
      },
      data,
    });
  },
  downloadLicense: ({ id }) => {
    return ApiService.sendRequest({
      resource: {
        ...Resources.downloadLicense,
        uri: Resources.downloadLicense.uri
          .replace("{licenseId}", id)
      }
    });
  },
};

export default BackendService;
